var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jtl-transfer-component" },
    [
      _c(
        "a-transfer",
        {
          attrs: {
            "data-source": _vm.transferData,
            "show-search": "",
            operations: ["选中", "取消选中"],
            "target-keys": _vm.targetKeys,
            render: function(item) {
              return "" + item.title
            }
          },
          on: { change: _vm.handleChange }
        },
        [
          _c(
            "span",
            { attrs: { slot: "notFoundContent" }, slot: "notFoundContent" },
            [_vm._v(" 没数据 ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }