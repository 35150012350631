import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlTableModel, FormControlDateModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import CommonService from '@/service/common';
import { Moment } from 'moment';
import { PropertyEntityType } from '@/model/enum';
import { QueryControl, QueryControlType } from '@/model/query-control';

export class MaintenanceEntityModel extends BaseEntityModel {
    @FormControl({
        label: '保养计划',
        type: FormControlType.TEXT,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '保养周期',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.getTimePlanList,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    timePlanId: string = undefined;
    timePlanIdName: string = undefined;

    @FormControl({
        label: '有效日期',
        type: FormControlType.SELECT_DATERANGE,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlDateModel)
    dateRange: Array<Moment> = undefined;
    beginDate: string = undefined;
    endDate: string = undefined;

    @FormControl({
        label: '是否启用',
        type: FormControlType.RADIO_GROUP,
        options: [{ name: '是', value: true },
            { name: '否', value: false }],
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    useFlag: boolean = true;

    @FormControl({
        label: '保养等级',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.WORK_ORDER_LEVEL_CONF,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    level: string = undefined;

    @FormControl({
        label: '关联工单',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllWorkOrderOptions,
        required: true,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlOptionModel)
    relationId: string = undefined;
    // linkedName:string = undefined;

    @FormControl({
        label: '保养设备',
        type: FormControlType.TABLE,
        required: true,
        columns: [{ title: '设备名称', dataIndex: 'title' }, { title: '规格型号', dataIndex: 'type' }],
        dataService: CommonService.getAssetsList,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' },
        deleteAction: true,
        addAction: true
    } as FormControlTableModel)
    assetList: Array<any> = [];
    assetIdList: Array<any> = [];

    @FormControl({
        label: '保养内容',
        type: FormControlType.TEXT_AREA,
        required: true,
        rows: 5,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' }
    } as FormControlTextModel)
    content: string = undefined;

    @FormControl({
        label: '使用备品',
        type: FormControlType.TABLE,
        columns: [{ title: '备品名称', dataIndex: 'title' }, { title: '规格型号', dataIndex: 'type' }],
        dataService: CommonService.getBackupAssetsList,
        layout: { labelCol: 4, wrapperCol: 19, width: '100% ' },
        deleteAction: true,
        addAction: true
    } as FormControlTableModel)
    backupAssetList: Array<any> = [];
    backupAssetIdList: Array<string> = undefined;

    // @FormControl({
    //     label: '巡检清单',
    //     type: FormControlType.TEXT,
    //     required: true,
    //     layout: { labelCol: 5, wrapperCol: 18, width: '100% ' }
    // } as FormControlTextModel)
    checkList: Array<{ method: string, project: string, type: string, required: boolean }> = undefined;

    statusName: string = undefined;
    createUserName: string = undefined;
    createdTime: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name'
            },
            {
                title: '保养周期',
                dataIndex: 'timePlanIdName'
            },
            {
                title: '创建人',
                dataIndex: 'createUserName'
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime'
            },
            {
                title: '状态',
                dataIndex: 'useFlag',
                scopedSlots: { customRender: 'status' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class MaintenanceQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    @QueryControl({
        label: '状态',
        type: QueryControlType.SELECT,
        options: [
            {
                value: 'ALL',
                name: '全部'
            },
            {
                value: 'ON',
                name: '启用'
            },
            {
                value: 'OFF',
                name: '禁用'
            },
            {
                value: 'EXPIRED',
                name: '过期'
            }
        ]
    })
    status: string ='ALL';

    toService() {
        const data = super.toService();
        if (this.name || this.status) {
            data.params = {
                name: this.name,
                status: this.status
            };
            delete data.name;
            delete data.status;
        }

        return data;
    }
}
