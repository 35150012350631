var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "maintenance-component page-component" },
    [
      _c("jtl-form", {
        ref: "basicForm",
        on: { formTableAdd: _vm.formTableAdd }
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "jtl-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.viewType === _vm.ViewModeType.VIEW
              },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          ),
          _c(
            "jtl-button",
            {
              staticStyle: { "margin-left": "20px" },
              on: { click: _vm.cancel }
            },
            [_vm._v("取消")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.title, visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c("jtl-transfer-component", {
            ref: "transfer",
            attrs: { "transfer-data": _vm.transferData, keys: _vm.keys }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }