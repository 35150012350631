



















import { Component, Ref, Prop, Mixins } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DrawerComponent from '@/mixins/drawer-component';
import FormComponent from '@/mixins/form-component';
import { MaintenanceEntityModel } from '@/entity-model/maintenance-entity';
import { ViewModeType } from '@/model/enum';
import MaintenanceService from '@/service/maintenance';
import JTLTransferComponent from '@/components/jtl-transfer/index.vue';
import { DATE_FORMAT } from '@/filter/date-format';
import moment, { Moment } from 'moment';
import common from '@/service/common';

@Component({
    components: {
        'jtl-transfer-component': JTLTransferComponent
    }
})
export default class MaintenanceDetailComponent extends Mixins(BaseComponent, DrawerComponent) {
        @Prop()
        record;

        @Prop()
        viewType;

        visible: boolean =false;
        title: string='';
        transferData: any = [];
        keys: string[] = [];
        currentFormControl: any = undefined;
        ViewModeType = ViewModeType;

        /**
         * 表单组件
         */
        @Ref()
        basicForm: FormComponent<any>;

        maintenanceEntityModel: MaintenanceEntityModel;
        service: any;

        created() {
            this.service = MaintenanceService;

            this.$nextTick(() => {
                if (this.record) {
                    this.maintenanceEntityModel = this.record;

                    this.maintenanceEntityModel['dateRange'] = [moment(this.maintenanceEntityModel.beginDate, 'YYYY-MM-DD'), moment(this.maintenanceEntityModel.endDate, 'YYYY-MM-DD')];
                    common.getAssetsList().then(res => {
                        this.maintenanceEntityModel.assetList = res.filter(item => { return this.maintenanceEntityModel.assetIdList.indexOf(item.value) >= 0; });
                        this.maintenanceEntityModel.assetList = this.maintenanceEntityModel.assetList.map(dt => { return { key: dt.value, title: dt.name, type: dt.type }; });
                        common.getBackupAssetsList().then(res => {
                            this.maintenanceEntityModel.backupAssetList = res.filter(item => { return this.maintenanceEntityModel.backupAssetIdList.indexOf(item.value) >= 0; });
                            this.maintenanceEntityModel.backupAssetList = this.maintenanceEntityModel.backupAssetList.map(dt => { return { key: dt.value, title: dt.name, type: dt.type }; });
                            this.basicForm.initForm(this.maintenanceEntityModel, ViewModeType.VIEW);
                        });
                    });
                } else {
                    this.maintenanceEntityModel = new MaintenanceEntityModel();
                    this.basicForm.initForm(this.maintenanceEntityModel, ViewModeType.NEW);
                }
            });
        }

        save() {
            this.basicForm.submitForm().then(res => {
                this.basicForm.formModel.beginDate = (_.get(this.basicForm.formModel.dateRange, '[0]') as Moment)?.startOf('day').format(DATE_FORMAT);
                this.basicForm.formModel.endDate = (_.get(this.basicForm.formModel.dateRange, '[1]') as Moment)?.startOf('day').add(1, 'day').format(DATE_FORMAT);
                delete this.basicForm.formModel.dateRange;
                this.basicForm.formModel.assetIdList = this.basicForm.formModel['assetList'].map(item => { return item.key; });
                this.basicForm.formModel.backupAssetIdList = this.basicForm.formModel['backupAssetList'].map(item => { return item.key; });

                if (this.basicForm.formModel.uuid) {
                    delete this.basicForm.formModel.uuid;
                }

                if (this.basicForm.formModel.createdTime) {
                    delete this.basicForm.formModel.createdTime;
                }

                this.service.create(this.basicForm.formModel).then(res => {
                    this.cancel();
                });
            });
        }

        formTableAdd(item) {
            if (item) {
                this.currentFormControl = item;
                this.title = item.label;

                if (item.dataService) {
                    item.dataService().then(res => {
                        this.transferData = res.map(dt => {
                            return { key: dt.value, title: dt.name, type: dt.type };
                        });

                        this.keys = this.basicForm.formModel[this.currentFormControl.key].map(dt => { return dt.key; });
                        this.visible = true;
                    });
                }
            }
        }

        handleOk() {
            this.basicForm.formModel[this.currentFormControl.key] = this.$refs['transfer']['targetData'];
            this.visible = false;
        }

        handleCancel() {
            this.visible = false;
        }

        cancel() {
            this.$emit('showDrawer');
        }
}
